<template>
 <sdDrawer
  ref="shopSubProductAddRef"
  :form="form"
  :showOpenButton="false"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product configuratie"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" layout="vertical" @finish="updateShopSubProduct">
    <a-row :gutter="16">
     <a-col :xs="24">
      <Upload>
       <figure class="photo-upload align-center-v">
        <img
         v-if="form.imageFileURL && form.imageFileURL !== ''"
         :src="form.imageFileURL"
         alt=""
         style="max-width: 120px"
        />
        <img
         v-else
         :src="require('@/static/img/avatar/profileImage.png')"
         alt=""
         style="max-width: 120px"
        />
        <figcaption>
         <a-upload
          :action="`${fileUrl}products/${params.id}/subproducts/${form.id}/file`"
          :disabled="!$can('update', 'user')"
          :headers="fileUploadHeaders"
          :multiple="false"
          name="file"
          @change="handleUpload">
          <a class="btn-upload" to="#">
           <sdFeatherIcons size="16" type="camera"/>
          </a>
         </a-upload>
        </figcaption>
       </figure>
      </Upload>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item label="Artikelnummer" name="externalReference">
       <a-input v-model:value="form.externalReference" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: amountValidator}]"
                   label="Bedrag" name="amount">
       <a-input v-model:value="form.amount" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Actief"
                   name="active">
       <a-select v-model:value="form.active" :allowClear="true"
                 :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                         :value="item.value" name="active">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Auto synchronisatie"
                   name="hasAutoSync">
       <a-select v-model:value="form.hasAutoSync" :allowClear="true"
                 :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                         :value="item.value" name="hasAutoSync">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Categorie"
                   name="subCategoryID">
       <a-select v-model:value="form.subCategoryID" :allowClear="true"
                 :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in productSubCategories" :key="index"
                         :value="item.id" name="subCategoryID">
         {{ item.name }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :xs="24">
      <a-form-item label="Omschrijving">
       <div class="sDash_editor">
 <textarea
  v-model="form.description"
  :disabled="!$can('update', 'product')"
  class="form-control w-100"
  placeholder="Voer hier de productbeschrijving in"
  rows="6"
 ></textarea>

       </div>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :loading="loadingPatchShopSubProduct" class="btn-signin" html-type="submit" type="primary"> Opslaan
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "vuex";
import {FormValidationWrap, Upload} from "@/view/styled";
import {useRoute} from "vue-router";
import {getItem} from "@/utility/localStorageControl";
import {getApiURL} from "@/config/dataService/dataService";
import {defaultOptions,} from "../../../utility/enums";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {amountValidator} from "@/utility/validators";

const EditShopSubProduct = defineComponent({
 name: 'EditShopSubProduct',
 components: {
  FormValidationWrap,
  Upload,
 },
 data() {
  return {
   loading: false,
   fileUrl: getApiURL(),
   editor: ClassicEditor,
   editorConfig: {
    height: 200,
    autoParagraph: false,
   },
  }
 },
 setup() {
  const shopSubProductAddRef = ref();
  const formRef = ref();
  const {params} = useRoute();
  const {state, dispatch} = useStore();
  const productsSubProducts = computed(() => state.shop.productsSubProducts);
  const loadingPatchShopSubProduct = computed(() => state.user.loadingPatchShopSubProduct);
  const form = computed(() => state.shop.subProduct);
  const productSubCategories = computed(() => state.shop.productSubCategories);

  const closeDrawer = () => {
   shopSubProductAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('resetShopSubProduct');
   dispatch('fetchShopSubProducts', {id: params.id, page: productsSubProducts.value.currentPage})
  }

  const showDrawer = async (id) => {
   await dispatch('getShopSubProduct', {shopId: params.id, id: id});
   await shopSubProductAddRef.value.showDrawer();
  }
  const updateShopSubProduct = () => {
   form.value.shopId = params.id;
   dispatch('patchShopSubProduct', {value: form.value, close: closeDrawer});
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('getShopSubProduct', {shopId: params.id, id: form.value.id});
    await dispatch('fileUploadedSuccess');
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  return {
   form,
   updateShopSubProduct,
   shopSubProductAddRef,
   formRef,
   showDrawer,
   fileUploadHeaders,
   handleUpload,
   defaultOptions,
   loadingPatchShopSubProduct,
   amountValidator,
   productSubCategories,
   params,
  };
 },
});

export default EditShopSubProduct;
</script>
